
import { $gettext } from 'vue-gettext';
import { availableLanguages, currentLanguageISOString, getTranslated, getTranslatedKey, getTranslatedName, TranslatedKey } from '@/translation';
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { defaultRecording, Recording, slug, useVideoStore } from '@/stores/video';
// TODO: Centralize the email notification preview type instead of importing a "download" type in a "video" component
import { EmailNotificationPreview } from '@/stores/download';
import { fetchVideo } from '@/utils/youtube-api';
import { filterObject, isEqual, KeyOf, OptionalKeys } from '@/utils/object';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Product, useVersionStore } from '@/stores/version';
import { slashIcon } from '../icon/BpIcon';
import { useFormErrors } from '@/utils/form-errors';
import { useRouter } from 'vue-router';
import { uuid } from '@/utils/string';
import BpToast from '../toast/BpToasts';
import clone from '@sahnee/clone';

export default defineComponent({
  name: 'bp-video-recording-editor',
  props: {
    modelValue: Object as PropType<Recording>,
    loading: Boolean,
  },
  setup(props) {
    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const versionStore = useVersionStore();
    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();
    const isEdit = computed(() => router.currentRoute.value.name?.toString().endsWith('edit'));

    ///-------------------------------------------------------------------
    /// RECORDING
    ///-------------------------------------------------------------------

    const initialRecording: OptionalKeys<Recording, '_id' | '_rev'> = {
      ...defaultRecording,
      parent_id: (router.currentRoute.value.query.parent as string) || 'root',
    };

    const recording = ref<OptionalKeys<Recording, '_id' | '_rev'>>({ ...clone(initialRecording), ...props.modelValue ? clone(props.modelValue) : {} });
    watch(() => props.modelValue, reset, { immediate: true });

    ///-------------------------------------------------------------------
    /// RESET
    ///-------------------------------------------------------------------

    function reset() {
      if (props.modelValue && isEqual(recording.value, props.modelValue)) { return }
      recording.value = { ...clone(initialRecording), ...props.modelValue ? clone(props.modelValue) : {} };
    }

    ///-------------------------------------------------------------------
    /// PARENT
    ///-------------------------------------------------------------------

    function parentSortProperty(item: Recording): KeyOf<Recording> {
      return item._id === 'root' ? 'order' : `name.${currentLanguageISOString()}`;
    }

    ///-------------------------------------------------------------------
    /// ERROR
    ///-------------------------------------------------------------------

    const { errors, setError } = useFormErrors<Recording>()

    ///-------------------------------------------------------------------
    /// SAVE
    ///-------------------------------------------------------------------

    const store = useVideoStore(true);

    async function save() {
      let response;
      recording.value.doc_type = 'video_recording';
      if (!recording.value.slug) {
        recording.value.slug = slug(recording.value, store.getVideos());
      }
      if (recording.value._id) {
        response = await store.update(recording.value);
      } else {
        response = await store.create(recording.value);
      }
      if (response?.success) {
        BpToast.show({
          color: 'green',
          title: recording.value._id
            ? $gettext('Recording successfully updated')
            : $gettext('Recording successfully created'),
          content: recording.value._id
            ? $gettext('The recording was successfully updated.')
            : $gettext('The recording was successfully created.'),
          icon: 'circle-check',
        });
        router.replace({ name: 'admin.video.overview' });
      } else {
        BpToast.show({
          color: 'red',
          title: recording.value._id
            ? $gettext('Failed to update recording')
            : $gettext('Failed to create recording'),
          content: recording.value._id
            ? $gettext('The recording could not be updated: %{error}', { error: response?.error })
            : $gettext('The recording could not be created: %{error}', { error: response?.error }),
          icon: 'circle-check',
        });
      }
    }

    const saveDisabled = computed(() => errors.value.size > 0);

    ///-------------------------------------------------------------------
    /// YOUTUBE
    ///-------------------------------------------------------------------

    function pasteYoutubeId(event: ClipboardEvent) {
      const text = event.clipboardData?.getData('text') || '';
      if (/^https?:\/\/.*youtube.*/.test(text)) {
        recording.value.youtube_id = (new Proxy(new URLSearchParams(text.split('?')[1]), {
          get: (searchParams, prop: string) => searchParams.get(prop),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }) as any).v
        return;
      } else if (/^https?:\/\/.*youtu\.be.*/.test(text)) {
        recording.value.youtube_id = text.split('?')[0].split('/').pop() ?? '';
        return;
      }
      recording.value.youtube_id = text;
    }

    async function fetchData() {
      const id = recording.value.youtube_id;
      if (!id) {
        return;
      }

      // Fetch video data from YouTube Data API
      const { name, description, tags } = await fetchVideo(id);
      recording.value.name = { ...name, ...filterObject(recording.value.name || {}, (_key, value) => !!value)}
      recording.value.description = { ...description, ...filterObject(recording.value.description || {}, (_key, value) => !!value && value !== '<p></p>')}
      recording.value.tags = Array.from(new Set([...tags, ...(recording.value.tags || [])].map(tag => tag.toLowerCase())));
    }

    ///-------------------------------------------------------------------
    /// EMAIL NOTIFICATION PREVIEW
    ///-------------------------------------------------------------------

    const emailNotificationPreview = ref<EmailNotificationPreview>();
    const emailNotificationPreviewLanguage = ref<TranslatedKey>('de');
    const emailNotificationWindow = ref(false);
    const unique = uuid();
    
    async function showEmailNotificationPreview() {
      const preview = await store.previewEmailNotification(recording.value);
      if (typeof preview === 'string') return;
      emailNotificationPreview.value = preview;
      emailNotificationWindow.value = true;
    }

    function closeEmailNotificationPreview() {
      emailNotificationWindow.value = false;
      emailNotificationPreview.value = undefined;
    }

    function setLanguage(lang: string) {
      emailNotificationPreviewLanguage.value = getTranslatedKey(lang);
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      availableLanguages,
      closeEmailNotificationPreview,
      currentLanguageISOString,
      emailNotificationPreview,
      emailNotificationPreviewLanguage,
      emailNotificationWindow,
      errors,
      fetchData,
      getTranslated,
      getTranslatedKey,
      getTranslatedName,
      isEdit,
      parentSortProperty,
      pasteYoutubeId,
      recording,
      reset,
      save,
      saveDisabled,
      setError,
      setLanguage,
      showEmailNotificationPreview,
      slashIcon,
      slug,
      store,
      unique,
      versionStore,
    }
  }
})


