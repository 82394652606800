
import { defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { Recording, useVideoStore } from '@/stores/video';
import BpVideoRecordingEditorVue from '@/components/video/BpVideoRecordingEditor.vue';

export default defineComponent({
  name: 'video-category-editor',
  components: {
    BpVideoRecordingEditor: BpVideoRecordingEditorVue
  },
  setup() {
    const router = useRouter();

    const store = useVideoStore(true);

    const recording = ref<Recording>();

    function loadVideo() {
      if (router.currentRoute.value.name && (router.currentRoute.value.name as string).endsWith('edit')) {
        const doc = store.getById(router.currentRoute.value.params.id as string)
        recording.value = doc as Recording;
      }
    }

    watch(() => store.isLoading(), () => {
      if (!store.loaded || store.isLoading()) { return }
      loadVideo()
    }, { immediate: true });

    return {
      recording,
      store,
    }
  }
});
