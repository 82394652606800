import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"opacity":"0.5"}
}
const _hoisted_2 = { style: {"opacity":"0.5"} }
const _hoisted_3 = { class: "bp-video-file-editor__language-selector" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_button = _resolveComponent("bp-button")!
  const _component_bp_label = _resolveComponent("bp-label")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_select = _resolveComponent("bp-select")!
  const _component_bp_wysiwyg_editor = _resolveComponent("bp-wysiwyg-editor")!
  const _component_bp_form_button = _resolveComponent("bp-form-button")!
  const _component_bp_card = _resolveComponent("bp-card")!
  const _component_bp_iframe = _resolveComponent("bp-iframe")!
  const _component_bp_window = _resolveComponent("bp-window")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_bp_card, {
      class: "bp-video-recording-editor",
      loading: _ctx.loading,
      "fill-height": "",
      "fill-width": ""
    }, {
      header: _withCtx(() => [
        (!_ctx.isEdit)
          ? (_openBlock(), _createBlock(_component_translate, {
              key: 0,
              tag: "h2"
            }, {
              default: _withCtx(() => _cache[25] || (_cache[25] = [
                _createTextVNode("New recording")
              ])),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_translate, {
              key: 1,
              tag: "h2",
              "translate-params": { recording: _ctx.getTranslated(_ctx.recording.name) }
            }, {
              default: _withCtx(() => _cache[26] || (_cache[26] = [
                _createTextVNode("Edit recording \"%{recording}\"")
              ])),
              _: 1
            }, 8, ["translate-params"]))
      ]),
      footer: _withCtx(() => [
        (!_ctx.isEdit && _ctx.recording.email_notification)
          ? (_openBlock(), _createBlock(_component_bp_form_button, {
              key: 0,
              disabled: _ctx.saveDisabled,
              errors: _ctx.errors,
              icon: ['far', 'envelope'],
              action: _ctx.showEmailNotificationPreview
            }, {
              default: _withCtx(() => [
                _createVNode(_component_translate, null, {
                  default: _withCtx(() => _cache[36] || (_cache[36] = [
                    _createTextVNode("Show email notification preview")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["disabled", "errors", "action"]))
          : (_openBlock(), _createBlock(_component_bp_form_button, {
              key: 1,
              type: "save",
              disabled: _ctx.saveDisabled,
              errors: _ctx.errors,
              action: _ctx.save
            }, null, 8, ["disabled", "errors", "action"])),
        _createVNode(_component_bp_form_button, {
          type: "reset",
          action: _ctx.reset
        }, null, 8, ["action"]),
        _createVNode(_component_bp_form_button, {
          type: "cancel",
          to: { name: 'admin.video.overview' }
        })
      ]),
      default: _withCtx(() => [
        (_ctx.recording)
          ? (_openBlock(), _createElementBlock("form", {
              key: 0,
              class: "bp-video-recording-editor__form",
              onSubmit: _cache[23] || (_cache[23] = _withModifiers(() => {}, ["prevent"]))
            }, [
              _createVNode(_component_bp_label, {
                label: _ctx.$gettext('YouTube ID'),
                required: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_bp_input, {
                    onChangeValid: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setError('youtube_id', _ctx.$gettext('YouTube ID'), $event))),
                    onPaste: _withModifiers(_ctx.pasteYoutubeId, ["stop","prevent"]),
                    required: "",
                    modelValue: _ctx.recording.youtube_id,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.recording.youtube_id) = $event))
                  }, {
                    text: _withCtx(() => [
                      _createElementVNode("span", null, [
                        _createVNode(_component_translate, null, {
                          default: _withCtx(() => _cache[27] || (_cache[27] = [
                            _createTextVNode("Copy the ID from the URL of any YouTube video, e.g.")
                          ])),
                          _: 1
                        }),
                        _cache[28] || (_cache[28] = _createTextVNode(" ")),
                        _cache[29] || (_cache[29] = _createElementVNode("a", {
                          href: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
                          target: "_blank",
                          style: {"color":"var(--theme-text-secondary)","text-decoration":"none"}
                        }, [
                          _createTextVNode("https://www.youtube.com/watch?v="),
                          _createElementVNode("strong", null, "dQw4w9WgXcQ")
                        ], -1))
                      ])
                    ]),
                    _: 1
                  }, 8, ["onPaste", "modelValue"]),
                  _createVNode(_component_bp_button, {
                    class: "flex-0 ws-nw",
                    disabled: !_ctx.recording.youtube_id,
                    icon: ['far', 'globe'],
                    large: "",
                    onClick: _ctx.fetchData
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => _cache[30] || (_cache[30] = [
                          _createTextVNode("Fetch data")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_bp_input, {
                label: _ctx.$gettext('Name'),
                onChangeValid: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setError('name', _ctx.$gettext('Name'), $event))),
                required: "",
                maxlength: 200,
                modelValue: _ctx.recording.name,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.recording.name) = $event))
              }, null, 8, ["label", "modelValue"]),
              _createVNode(_component_bp_select, {
                data: _ctx.store.getParents({ excludeIds: _ctx.recording._id, includeRoot: true }).map(recording => ({ ...recording, icon: ['far', 'folder']})),
                label: _ctx.$gettext('Parent category'),
                "search-property": `name.${_ctx.currentLanguageISOString()}`,
                "sort-property": _ctx.parentSortProperty,
                onChangeValid: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setError('parent_id', _ctx.$gettext('Parent category'), $event))),
                required: "",
                modelValue: _ctx.recording.parent_id,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.recording.parent_id) = $event))
              }, {
                option: _withCtx(({ option }) => [
                  _createVNode(_component_bp_icon, {
                    icon: option.icon
                  }, null, 8, ["icon"]),
                  _createElementVNode("span", null, _toDisplayString(_ctx.getTranslated(option.name)), 1),
                  (_ctx.store.getPathById(option._id).length > 0)
                    ? (_openBlock(), _createElementBlock("em", _hoisted_1, [
                        _cache[31] || (_cache[31] = _createTextVNode(" (")),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.getPathById(option._id), (category, index) => {
                          return (_openBlock(), _createElementBlock(_Fragment, {
                            key: category._id
                          }, [
                            (index !== 0)
                              ? (_openBlock(), _createBlock(_component_bp_icon, {
                                  key: 0,
                                  class: "ml-sm mr-sm",
                                  icon: "angle-right"
                                }))
                              : _createCommentVNode("", true),
                            _createElementVNode("span", null, _toDisplayString(_ctx.getTranslated(category.name)), 1)
                          ], 64))
                        }), 128)),
                        _cache[32] || (_cache[32] = _createTextVNode(") "))
                      ]))
                    : _createCommentVNode("", true)
                ]),
                "option-root": _withCtx(({ option }) => [
                  _createVNode(_component_bp_icon, {
                    icon: _ctx.slashIcon(option.icon)
                  }, null, 8, ["icon"]),
                  _createElementVNode("em", _hoisted_2, "(" + _toDisplayString(_ctx.getTranslated(option.name)) + ")", 1)
                ]),
                _: 1
              }, 8, ["data", "label", "search-property", "sort-property", "modelValue"]),
              _createVNode(_component_bp_wysiwyg_editor, {
                label: _ctx.$gettext('Description'),
                onChangeValid: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setError('description', _ctx.$gettext('Description'), $event))),
                type: "textarea",
                maxlength: 500,
                modelValue: _ctx.recording.description,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.recording.description) = $event))
              }, null, 8, ["label", "modelValue"]),
              _createVNode(_component_bp_wysiwyg_editor, {
                label: _ctx.$gettext('Hint'),
                onChangeValid: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setError('hint', _ctx.$gettext('Hint'), $event))),
                type: "textarea",
                maxlength: 500,
                modelValue: _ctx.recording.hint,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.recording.hint) = $event))
              }, null, 8, ["label", "modelValue"]),
              _createVNode(_component_bp_input, {
                label: _ctx.$gettext('URL slug'),
                placeholder: _ctx.slug(_ctx.recording, _ctx.store.getVideos()),
                onChangeValid: _cache[10] || (_cache[10] = ($event: any) => (_ctx.setError('slug', _ctx.$gettext('URL slug'), $event))),
                modelValue: _ctx.recording.slug,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.recording.slug) = $event)),
                maxlength: 200
              }, {
                text: _withCtx(() => [
                  _createElementVNode("span", null, [
                    _createVNode(_component_translate, null, {
                      default: _withCtx(() => _cache[33] || (_cache[33] = [
                        _createTextVNode("The part of the URL that identifies this particular recording in an easy-to-read form.")
                      ])),
                      _: 1
                    }),
                    _cache[35] || (_cache[35] = _createTextVNode(" ")),
                    _createVNode(_component_translate, { tag: "strong" }, {
                      default: _withCtx(() => _cache[34] || (_cache[34] = [
                        _createTextVNode("It should be in English for consistency reasons. Defaults to the english name of this recording.")
                      ])),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }, 8, ["label", "placeholder", "modelValue"]),
              _createVNode(_component_bp_input, {
                label: _ctx.$gettext('Date'),
                onChangeValid: _cache[12] || (_cache[12] = ($event: any) => (_ctx.setError('date', _ctx.$gettext('Date'), $event))),
                type: "date",
                modelValue: _ctx.recording.date,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.recording.date) = $event))
              }, null, 8, ["label", "modelValue"]),
              _createVNode(_component_bp_select, {
                data: _ctx.versionStore.getRootProducts(),
                transform: (product) => _ctx.versionStore.getName(product),
                label: _ctx.$gettext('Product'),
                clearable: "",
                modelValue: _ctx.recording.product,
                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.recording.product) = $event))
              }, null, 8, ["data", "transform", "label", "modelValue"]),
              (!_ctx.isEdit && !!_ctx.recording.product)
                ? (_openBlock(), _createBlock(_component_bp_input, {
                    key: 0,
                    label: _ctx.$gettext('Email notification'),
                    text: _ctx.$gettext('Send an email to all registered users who accepted to be notified on release of a new video.'),
                    onChangeValid: _cache[15] || (_cache[15] = ($event: any) => (_ctx.setError('notification', _ctx.$gettext('Email notification'), $event))),
                    flush: "",
                    type: "checkbox",
                    modelValue: _ctx.recording.email_notification,
                    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.recording.email_notification) = $event))
                  }, null, 8, ["label", "text", "modelValue"]))
                : _createCommentVNode("", true),
              _createVNode(_component_bp_input, {
                label: _ctx.$gettext('Order'),
                modelValue: _ctx.recording.order,
                "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.recording.order) = $event)),
                type: "number",
                onChangeValid: _cache[18] || (_cache[18] = ($event: any) => (_ctx.setError('order', _ctx.$gettext('Order'), $event)))
              }, null, 8, ["label", "modelValue"]),
              _createVNode(_component_bp_select, {
                data: _ctx.store.getTags().map(tag => ({ ...tag, icon: 'hashtag' })),
                label: _ctx.$gettext('Tags'),
                onChangeValid: _cache[19] || (_cache[19] = ($event: any) => (_ctx.setError('tags', _ctx.$gettext('Tags'), $event))),
                clearable: "",
                "create-new-on-enter": "",
                multiple: "",
                "search-property": "name",
                modelValue: _ctx.recording.tags,
                "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.recording.tags) = $event))
              }, null, 8, ["data", "label", "modelValue"]),
              _createVNode(_component_bp_input, {
                label: _ctx.$gettext('Featured'),
                text: _ctx.$gettext('Feature this recording at the top of the video area.'),
                onChangeValid: _cache[21] || (_cache[21] = ($event: any) => (_ctx.setError('featured', _ctx.$gettext('Featured'), $event))),
                flush: "",
                type: "checkbox",
                modelValue: _ctx.recording.featured,
                "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.recording.featured) = $event))
              }, null, 8, ["label", "text", "modelValue"])
            ], 32))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["loading"]),
    (_ctx.emailNotificationPreview)
      ? (_openBlock(), _createBlock(_component_bp_window, {
          key: 0,
          modelValue: _ctx.emailNotificationWindow,
          "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.emailNotificationWindow) = $event))
        }, {
          header: _withCtx(() => [
            _createVNode(_component_translate, { tag: "h2" }, {
              default: _withCtx(() => _cache[37] || (_cache[37] = [
                _createTextVNode("Email notification preview")
              ])),
              _: 1
            })
          ]),
          footer: _withCtx(() => [
            _createVNode(_component_bp_form_button, {
              icon: ['far', 'floppy-disk'],
              color: "green",
              disabled: _ctx.saveDisabled,
              errors: _ctx.errors,
              action: _ctx.save
            }, {
              default: _withCtx(() => [
                _createVNode(_component_translate, null, {
                  default: _withCtx(() => _cache[39] || (_cache[39] = [
                    _createTextVNode("Save and send email notifications")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["disabled", "errors", "action"]),
            _createVNode(_component_bp_form_button, {
              type: "cancel",
              action: _ctx.closeEmailNotificationPreview
            }, null, 8, ["action"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_translate, {
              tag: "p",
              "translate-n": _ctx.emailNotificationPreview.users,
              "translate-params": { user_count: _ctx.emailNotificationPreview.users },
              "translate-plural": "The following email will be sent to <strong>%{user_count} users</strong>:"
            }, {
              default: _withCtx(() => _cache[38] || (_cache[38] = [
                _createTextVNode("The following email will be sent to "),
                _createElementVNode("strong", null, "%{user_count} user", -1),
                _createTextVNode(":")
              ])),
              _: 1
            }, 8, ["translate-n", "translate-params"]),
            _createElementVNode("h3", null, _toDisplayString(_ctx.emailNotificationPreview[_ctx.emailNotificationPreviewLanguage]?.subject), 1),
            _createVNode(_component_bp_iframe, {
              "model-value": _ctx.emailNotificationPreview[_ctx.emailNotificationPreviewLanguage]?.body || ''
            }, null, 8, ["model-value"]),
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.availableLanguages).sort((a, b) => a < b ? -1 : 1), (lang, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: lang.toLowerCase()
                }, [
                  (index !== 0)
                    ? (_openBlock(), _createBlock(_component_bp_icon, {
                        key: 0,
                        icon: "slash-forward",
                        "fixed-width": false
                      }))
                    : _createCommentVNode("", true),
                  (_openBlock(), _createBlock(_resolveDynamicComponent('bp-input'), {
                    "model-value": _ctx.emailNotificationPreviewLanguage,
                    name: 'language-selector-' + _ctx.unique,
                    text: _ctx.getTranslatedName(lang),
                    value: _ctx.getTranslatedKey(lang),
                    "onUpdate:modelValue": _ctx.setLanguage,
                    seamless: "",
                    type: "radio"
                  }, null, 8, ["model-value", "name", "text", "value", "onUpdate:modelValue"]))
                ], 64))
              }), 128))
            ])
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}